import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./components/CodeBlock";

const DEFAULT_MODEL = "gpt-3.5-turbo";
const TEMPERATURE = 0.3;
function Home() {
  const [socket, setSocket] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [chatDelayMessage, setChatDelayMessage] = useState("");

  const sendMessage = useCallback(() => {
    if (inputMessage === "" || inputMessage.length < 3) {
      alert("Please enter a message");
      return false;
    }
    const sendMessage = {
      question: inputMessage,
      temperature: TEMPERATURE,
    };

    setMessages((prev) => [
      ...prev,
      { type: "You", class_name: "Q", message: inputMessage },
    ]);

    setChatMessage("");
    socket.send(JSON.stringify(sendMessage));
    setIsSending(true);

    return true;
  }, [inputMessage, socket]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();

        if (!sendMessage()) {
          return false;
        }
        setInputMessage("");

        return false;
      }
    },
    [sendMessage]
  );

  const onClickSend = useCallback(
    (e) => {
      e.preventDefault();

      if (!sendMessage()) {
        return false;
      }

      setInputMessage("");

      return false;
    },
    [sendMessage]
  );

  useEffect(() => {
    const anchor = document.querySelectorAll("div.anchor");
    if (anchor && anchor.length > 0) {
      anchor[anchor.length - 1].scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    let interval = null;
    if (isSending && interval === null) {
      interval = setInterval(() => {
        setChatDelayMessage((prev) => {
          if (prev.length >= 5) {
            return "";
          }
          return prev + ".";
        });
      }, 500);
    } else {
      if (interval !== null) {
        setChatDelayMessage("");
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);
  }, [isSending]);

  useEffect(() => {
    if (!socket) {
      const protocol = window.location.protocol === "https:" ? "wss" : "ws";
      const HOST =
        process.env.NODE_ENV === "production"
          ? `${protocol}://${window.location.host}/ws/pymupdf`
          : `${protocol}://localhost:8100/ws/pymupdf`;

      const ws = new WebSocket(HOST);
      setSocket(ws);

      ws.onopen = () => {};
      ws.onclose = () => {};
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message) {
          if (message.type === "response") {
            if (!message.stream) {
              setChatMessage("");
              setMessages((prev) => [
                ...prev,
                {
                  type: "Dr. PDF",
                  class_name: "A",
                  message: message.message,
                },
              ]);
              setIsSending(false);
            } else {
              setChatMessage(message.message);
            }
          } else if (message.type === "stream_end") {
            setChatMessage("");
            setIsSending(false);
            setMessages((prev) => [
              ...prev,
              {
                type: "Dr. PDF",
                class_name: "A",
                message: message.message,
              },
            ]);
          } else if (message.type === "reject") {
            setIsSending(false);
            /*
              message: "minute, hour, daily"
            */
            // setChatMessage(message.message);
          }
        }
      };
    }
  }, [chatMessage, socket]);

  return (
    <>
      <div className="container sticky">
        <div className="row">
          <div className="col" id="input-holder">
            <div className="input-group">
              <textarea
                id="userInput"
                placeholder="Ask a question about PyMuPDF"
                aria-describedby="btnMessage"
                className="form-control"
                rows="1"
                onInput={(e) => {
                  setInputMessage(e.target.value);
                }}
                value={inputMessage}
                onKeyDown={onKeyDown}
                disabled={isSending}
              />
              <button
                type="button"
                onClick={onClickSend}
                disabled={isSending}
                className={inputMessage === "" ? "btn inactive" : "btn active"}
              >
                {isSending && <span className="spinner"></span>}
                {!isSending && <span>&#8673;</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="results">
        {messages.map((msg, index) => {
          return (
            <div key={`wrap_${index}`} className="container message">
              {index > 0 && msg.class_name === "Q" && (
                <div className="anchor"></div>
              )}
              <div className={msg.class_name} key={`profile_${index}`}>
                {msg.class_name === "Q" && (
                  <>
                    <div className="user">
                      <div className="icon">&#128172;</div>
                      <div className="title">{msg.type}</div>
                    </div>
                    <div className="query">{msg.message}</div>
                  </>
                )}
                {msg.class_name === "A" && (
                  <>
                    <div className="icon">&#128172;</div>
                    <div className="title">{msg.type}</div>
                  </>
                )}
              </div>
              {msg.class_name === "A" && (
                <div className="row chat-answer" key={`message_${index}`}>
                  <ReactMarkdown
                    components={{
                      code({
                        node,
                        inline,
                        className,
                        style,
                        children,
                        ...props
                      }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <CodeBlock
                            language={match[1]}
                            PreTag="div"
                            {...props}
                            value={String(children).replace(/\n$/, "")}
                          ></CodeBlock>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  >
                    {msg.message}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          );
        })}
        {isSending && (
          <div className="container message">
            <div className="A">
              <div className="icon">&#128172;</div>
              <div className="title">
                Dr. PDF {chatMessage === "" && chatDelayMessage}
              </div>
            </div>
            {chatMessage !== "" && (
              <div className="row chat-answer">
                <ReactMarkdown
                  components={{
                    code({
                      node,
                      inline,
                      className,
                      style,
                      children,
                      ...props
                    }) {
                      const match = /language-(\w+)/.exec(className || "");
                      return !inline && match ? (
                        <CodeBlock
                          language={match[1]}
                          PreTag="div"
                          {...props}
                          value={String(children).replace(/\n$/, "")}
                        ></CodeBlock>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                  }}
                >
                  {chatMessage}
                </ReactMarkdown>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
