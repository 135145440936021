import React, { useCallback, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  oneLight,
  coldarkDark,
  oneDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";

function CodeBlock({ children, ...props }) {
  const [isCopied, setIsCopied] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selecteMode, setSelecteMode] = useState("light");

  const onClickCopy = useCallback(
    (e) => {
      e.preventDefault();
      if (navigator.clipboard) {
        navigator.clipboard.writeText(props.value);
        setIsCopied(true);
      } else {
        const input = document.createElement("textarea");
        input.value = props.value;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        setIsCopied(true);
      }
    },
    [props.value]
  );

  const onSelectMode = useCallback((e) => {
    setSelecteMode(e.matches ? "dark" : "light");
  }, []);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [isCopied]);

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", onSelectMode);
    setSelecteMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", onSelectMode);
    };
  }, [onSelectMode]);

  return (
    <div
      className="col"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="copy-area">
        <span></span>
        {/* <span className="language-name">{props.language}</span> */}
        <button
          className={"btn-copy" + (isActive ? " active" : "")}
          onClick={onClickCopy}
          style={{ fontSize: "0.7rem" }}
        >
          {isCopied ? (
            "Copied!"
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-copy"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <title>Copy to clipboard</title>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="8" y="8" width="12" height="12" rx="2"></rect>
              <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
            </svg>
          )}
        </button>
      </div>
      <SyntaxHighlighter
        style={selecteMode === "light" ? oneLight : oneDark}
        {...props}
      >
        {props.value}
      </SyntaxHighlighter>
    </div>
  );
}

export default CodeBlock;
